<div class="container-fluid">
<section class="row">
    <div class="footer-section" style="width: 100%;text-align:center;">
  <div class="footer">
  <div class="container-fluid">
      <section class="row">
        
          <div class="col-md-3 footer-one" style="">
            <div class="foot-logo">
              <div class="row">
                 <div class="col-md-6 col-sm-6 col-6"><span ><img class="img-fluid" src="assets/img/logo-slogan-.png" alt=""></span></div>
               <div class="col-md-5 col-sm-5 col-5"><span  ><img class="img-fluid" src="assets/img/comcasa-logo.png" alt=""></span></div>
              </div>
             
         
            </div> 
             
             <p>Comercializadora de Carnes Salas S.A. de C.V.
              </p>
          <div class="social-icons"> 
             <a href="https://www.facebook.com/Kami-973163016167203/?modal=admin_todo_tour" target="_blank"  class="faa-parent animated-hover">
              <i class="fa fa-facebook-square  fa-3x faa-falling"  style="color:#E2432D ;"></i><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"></font></font></a>
               <a href="https://twitter.com/AlimentosKami" target="_blank"  class="faa-parent animated-hover">
              <i class="fa fa-twitter-square fa-3x faa-falling"  style="color:#E2432D ;"></i><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"></font></font></a>
               <a href="https://www.youtube.com/channel/UCr_r2OkCz78uQ65AT8flL7g?view_as=subscriber"  target="_blank" class="faa-parent animated-hover">
              <i class="fa fa-youtube-square fa-3x faa-falling"  style="color:#E2432D ;"></i><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"></font></font></a>
              <a href="https://api.whatsapp.com/send?phone=5516964590" target="_blank"  class="faa-parent animated-hover">
              <i class="fa fa-whatsapp fa-3x faa-falling"  style="color:#E2432D ;"></i><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"></font></font></a>
                

          </div>
          </div>
     <div class="col-md-3 footer-two">
              <h5><a style="color:#D93630" routerLink = "/inicio">MATRIZ</a></h5>
               <p>Av. 16 de septiembre No. 184 Bo. Xaltocán. Del. Xochimilco Ciudad de México <br>C.P 16090 <br>
                              Tel: 5676-5898  
                      </p> 
           
          </div>
          <div class="col-md-3 footer-three">
              <h5><a style="color:#D93630" routerLink = "/inicio">MILPA ALTA</a></h5>
               
               <p>Av. Jalisco 279 Bo. Santa Martha. Del. Milpa Alta Ciudad de México <br>C.P 12000
                            <br>
                              Tel: 2211-5465
                      </p> 
                
          </div>
          <div class="col-md-3 footer-four">

                 <h5><a style="color:#D93630" routerLink = "/inicio">AZTECAS</a></h5>
               
                      <p>Rey Topiltzin Mz. 69 Lt.16 Col. Ajusco. Del. Coyoacán Ciudad de México 
                        <br>C.P.04300 
                         <br>Tel: 5338-8372    
                      </p> 
          </div>  
                  
      <div class="clearfix"></div>
      </section>

     
  </div>
</div>

</div>

 
</section>

</div>