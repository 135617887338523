import { Component, OnInit } from '@angular/core';
import { Contacto } from 'src/app/models/contacto';
import { ContactoService } from 'src/app/service/contacto.service';
import { Meta, Title } from '@angular/platform-browser'



@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent implements OnInit {
  title = 'Kami quienes somos';

  public estatus = false;
  public mensaje: string;
  public contacto: Contacto;
  public privacidad: boolean;
  public loading: boolean;
  public btn: boolean;

  constructor(
    private _contactoService: ContactoService,
    private metaTagService: Meta,
    private titleService: Title) {
  }

  ngOnInit(): void {
    this.contacto = new Contacto('', '', '', '', '', '', '', '', '');

    this.titleService.setTitle(this.title);
    this.metaTagService.addTags([
      { name: 'description', content:'KAMI es una empresa familiar mexicana con 5 años'+
      ' en el mercado, ubicada al sur de la Ciudad de México.'+
       ' dedicada al procesamiento de carne de cerdo para'+
       ' la obtención de productos de valor agregado con un alto aporte nutricional'},
      { name: 'keywords', content: 'Venta de Jamón mayoreo, Distribuidor de Jamón, Jamón artesanal,'+ 
      ' Embutidos mayoreo, Jamón por mayoreo, Marca mexicana de jamones' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Kami' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'date', content: '2020-08-07', scheme: 'YYYY-MM-DD' },
      { charset: 'UTF-8' }
    ]);
  }

  guardarContacto() {
    this.loading = true;
    this.privacidad = false;

    this._contactoService.create(this.contacto).subscribe(contacto => {
      this.mensaje = contacto.mensaje;
      console.log(contacto);

      if (contacto.estatus == 200) {
        this.estatus = true;
        this.contacto = new Contacto('', '', '', '', '', '', '', '', '');
        setTimeout(() => {
          this.estatus = false;
          this.loading = false;

        },
          10000);
      } else {
        this.estatus = false;
        this.loading = false;

      }

    }, error => {
      this.estatus = true;
      this.mensaje = "* Hay un error al enviar tus comentarios verifica los datos!";
      setTimeout(() => {
        this.estatus = false;
        this.loading = false;

      },
        10000);

    })
    console.log(this.contacto);
  }
}
