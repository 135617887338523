<div class="row">
    <div class="col-lg-1"></div>
    <div class="col-lg-4 ">
<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="galeria.html" style="color:white;">  Producto Artesanal</a></li>
    <li class="breadcrumb-item active" aria-current="page"> Jamón Horneado</li>
  </ol>
</nav>

     <div class="img-thumbnail">
             <img src="assets/img/eutiquio/Jamon-horneado-one.jpg" class="img-fluid">
     </div>
    </div>
    <div class="col-lg-6  ">
      <ul class="nav nav-pills mb-3 link-tabs nav-tabs" id="pills-tab"
        role="tablist">
        <li class="nav-item  "><a class="nav-link active "
          id="pills-home-tab" data-toggle="pill" href="#pills-home"
          role="tab" aria-controls="pills-home" aria-selected="true">Descripción
            <p>del producto</p>
        </a></li>
        <li class="nav-item"><a class="nav-link" id="pills-profile-tab"
          data-toggle="pill" href="#pills-profile" role="tab"
          aria-controls="pills-profile" aria-selected="false">Presentación
            <p class="invisible">de</p>
        </a></li>
        <li class="nav-item"><a class="nav-link" id="pills-info-tab"
          data-toggle="pill" href="#pills-info" role="tab"
          aria-controls="pills-info" aria-selected="false">Información
            <p>nutrimental</p>
        </a></li>
        <li class="nav-item"><a class="nav-link text-center"
          id="pills-diario-tab" data-toggle="pill" href="#pills-diario"
          role="tab" aria-controls="pills-diario" aria-selected="false">Aporte <p>nutricional</p>
            
        </a></li>
        <li class="nav-item"><a class="nav-link" id="pills-produc-tab"
          data-toggle="pill" href="#pills-produc" role="tab"
          aria-controls="pills-produc" aria-selected="false">Manejo del
            <p>producto</p>
        </a></li>
      </ul>

      

      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <td>
              <p>Producto elaborado artesanalmente a base de
              piernas de cerdo seleccionadas y procesadas
              bajo estrictos estándares de calidad y sanidad.
              Adicionado con condimentos y una porción
              mínima de conservadores
              Perfil sensorial</p>
          <ol>Textura: firme, resistente al corte
          </ol>
          <ol>Olor: ligero
          </ol>
          <ol>Sabor:  ligeramente salado, poco ahumado
          </ol>
          <ol>Color: rosa homogéneo
          </ol>
          </td>
          </tr>
          </tbody>
          </table>
        </div>
        <div class="tab-pane fade" id="pills-profile" role="tabpanel"
          aria-labelledby="pills-profile-tab">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <td><h4>Presentacion</h4></td>
              </tr>
              <tr>
                <td>Rebanado 250 gr. Al vacío</td>
              </tr>
              <tr>
                <td>Barra 5 kg aprox.</td>
              </tr>
              <tr>
                <td>A granel</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="tab-pane fade" id="pills-info" role="tabpanel"
          aria-labelledby="pills-info-tab">
          <table class="table table-bordered col-lg-8">
            <thead>
              <tr>
                <th colspan="2" class="text-center">Informacion nutrimental
                </th>
              </tr>
              <tr>
                <th colspan="2" class="text-center">tamaño de porcion</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Contenido energetico</td>
                <td>110 Kcal</td>
              </tr>
              <tr>
                <td>Proteínas</td>
                <td>12g</td>
              </tr>
              <tr>
                <td>Lípidos</td>
                <td>2g</td>
              </tr>
              <tr>
                <td>Grasas saturadas</td>
                <td>1g</td>
              </tr>
              <tr>
                <td>Hidratos de carbono</td>
                <td>2g</td>
              </tr>
              <tr>
                <td>Azúcares</td>
                <td>0g</td>
              </tr>
              <tr>
                <td>Fibra dietética</td>
                <td>0mg</td>
              </tr>
              <tr>
                <td>Sodio</td>
                <td>1360mg</td>
              </tr>
              <tr>
                <td>Otras grasas</td>
                <td>2Kcal</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="tab-pane fade" id="pills-diario" role="tabpanel"
          aria-labelledby="pills-diario-tab">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <td colspan="5"><h4 class="text-center">Una porcion de
                    100g aporta</h4></td>
              </tr>
              <tr>
                <td><p>Energía</p> 110 Kcal</td>
                <td><p>Azúcares</p> 0 Kcal</td>
                <td><p>Otras grasas</p> 2 Kcal</td>
                <td><p>Grasas saturadas</p> 6 kcal</td>
                <td><p>Sodio</p> 14mg/g</td>
              </tr>
              <tr>
                <td><p></p> </td>
                <td>0%</td>
                <td>1%</td>
                <td>5%</td>
                <td>13%</td>
              </tr>
              <tr>
                <td colspan="5"><h4 class="text-center">*%Diario
                    basado en una dieta de 2000Kcal</h4></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="tab-pane fade" id="pills-produc" role="tabpanel"
          aria-labelledby="pills-produc-tab">
          <table class="table table-bordered">
            <th>
          <h6>
            <ol>Conservar en refrigeración (0-4°C) hasta su consumo.
            </ol>
            <ol>Consumo preferente antes de la fecha de caducidad.
            </ol>
            <ol>Vida de anaquel: 75 días
            </ol>
          </h6>
          </th>
          </table>
        </div>
      </div>
    </div>
    <!-- HTML -->
  </div>