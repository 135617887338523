<div class="row">
  <hr style="background-color: #FA3910;width:100%;">

    <div class="h-100  col-lg-3">
      <a routerLink = "/jamon-york"> <img src="assets/img/redondos/Jamon-york-one.jpg" alt="jamon york en comcasa" class="img-thumbnail rounded-circle" ></a>
      <div>
        <div class="col-lg-12">
          <table class=" table table-bordered text-center">
            <tbody>
              <tr>
                <td><a class="b" routerLink = "/jamon-york" style="color:white;">Jamón York</a></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="h-100 col-lg-3">
      <a routerLink = "/jamon-de-pavo"><img  src="assets/img/redondos/Jamon-de-pavo-two.jpg" alt="jamon de pavo en comcasa" class="img-thumbnail rounded-circle"></a>
      <div>
        <div class="col-lg-12">
          <table class=" table table-bordered text-center">
            <tbody>
              <tr>
                <td><a routerLink = "/jamon-de-pavo" style="color:white;">Jamón de Pavo</a></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="h-100 col-lg-3">
      <a routerLink = "/jamon-horneado"> <img
        src="assets/img/redondos/Jamon-horneado-two.jpg" alt="comercializadora comcasa jamones"
        class="img-thumbnail rounded-circle "></a>
      <div>
        <div class="col-lg-12">
          <table class=" table table-bordered text-center">
            <tbody>
              <tr>
                <td><a routerLink = "/jamon-horneado" style="color:white;">Jamón Horneado</a></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="h-100 col-lg-3">
      <a routerLink = "/jamon-tipo-americano"> <img
        src="assets/img/redondos/Jamon-americano-one.jpg" alt="comercializadora comcasa"
        class="img-thumbnail rounded-circle "></a>
      <div>
        <div class="col-lg-12">
          <table class=" table table-bordered text-center">
            <tbody>
              <tr>
                <td><a routerLink = "/jamon-tipo-americano" style="color:white;">Jamón Tipo
                    Americano</a></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="row">



    <div class="h-100 col-lg-3">
      <a routerLink = "/queso-de-puerco-kami"> <img src="assets/img/redondos/Queso-de-puerco-one.jpg"
        alt=" queso de puerco " class="img-thumbnail rounded-circle"></a>
      <div>
        <div class="col-lg-12">
          <table class=" table table-bordered text-center">
            <tbody>
              <tr>
                <td><a routerLink = "/queso-de-puerco-kami" style="color:white;">Queso de Puerco</a></td>

              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="h-100  col-lg-3">
      <a routerLink = "/tocino"> <img
        src="assets/img/redondos/Tocino-entero-two.jpg" alt="alta calidad en tocino comcasa"
        class="img-thumbnail rounded-circle "></a>
      <div>
        <div class="col-lg-12">
          <table class=" table table-bordered text-center">
            <tbody>
              <tr>
                <td><a routerLink = "/tocino" style="color:white;">Tocino Entero </a></td>

              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="h-100  col-lg-3">
      <a routerLink = "/longaniza"><img
        src="assets/img/redondos/Longaniza-two.jpg" alt="longaniza distribuida por comcasa"
        class="img-thumbnail rounded-circle "></a>
      <div>
        <div class="col-lg-12">
          <table class=" table table-bordered text-center">
            <tbody>
              <tr>
                <td><a routerLink = "/longaniza" style="color:white;">Longaniza </a></td>

              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="h-100  col-lg-3">
      <a routerLink = "/chuleta-ahumada"> <img
        src="assets/img/redondos/Chuleta-ahumada-one.jpg" alt="chueletas ahumadas"
        class="img-thumbnail rounded-circle "></a>
      <div>
        <div class="col-lg-12">
          <table class=" table table-bordered text-center">
            <tbody>
              <tr>
                <td><a routerLink = "/chuleta-ahumada" style="color:white;">Chuleta Ahumada</a></td>

              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>


  </div>
  <div class="row">

    

    <div class="h-100  col-lg-3">
    </div>

    <div class="h-100 col-lg-3">
      <a routerLink = "/carne-al-pastor"> <img src="assets/img/redondos/pastor_one.jpg" alt="carne de pastor de lata calidad comcasa" class="img-thumbnail rounded-circle"></a>
      <div>
        <div class="col-lg-12">
          <table class=" table table-bordered text-center">
            <tbody>
              <tr>
                <td><a routerLink = "/carne-al-pastor" style="color:white;">Carne al Pastor</a></td>

              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>




    <div class="h-100  col-lg-3">
      <a routerLink = "/chorizo-argentino"><img
        src="assets/img/redondos/Cho_arg_one.jpg" alt="chorizo de argentina en venta "
        class="img-thumbnail rounded-circle "></a>
      <div>
        <div class="col-lg-12">
          <table class=" table table-bordered text-center">
            <tbody>
              <tr>
                <td><a routerLink = "/chorizo-argentino" style="color:white;">Chorizo Argentino
                </a></td>

              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
    <hr style="background-color: #FA3910;width:100%;">

  </div>
  


