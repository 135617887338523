import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chuleta-ahumada',
  templateUrl: './chuleta-ahumada.component.html',
  styleUrls: ['../../../../assets/css/descripcion-productos.css']
})
export class ChuletaAhumadaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
