<div class="row">
  <hr style="background-color: #FA3910;width:100%;">

    <div class="col-lg-1"></div>
        <div class="col-lg-10">
          <h6 class="text-center">
            <ul class="list-group">  
               <li class="list-group-item text-justify"> Sabemos que usted se preocupa por ofrecer alimentos de la mejor calidad a sus clientes, kami cuenta con
                una amplia gama de productos, para satisfacer las necesidades del cliente mas exigente,
                asegurando la calidad, alto contenido cárnico, bajo en conservadores y lo más importante, su
                elaboración artesanal.
              </li>
            </ul>
          </h6> 
      </div>
    <div class="col-lg-1"></div>
</div>
 
<div class="row">
    <div class="col-lg-1"></div>
        <div class="col-lg-10">
          <h6 class="text-center">
            <ul class="list-group">  
               <li class="list-group-item text-justify"> Nuestros productos son: jamón horneado, tipo york, americano y pavo, además, tocino ahumado, queso
               de puerco, chuleta ahumada, longaniza, carne al pastor, tocino y chorizo argentino.
              </li>
            </ul>
          </h6> 
      </div>
    <div class="col-lg-1"></div>
</div>
<div class="row">
    <div class="col-lg-1"></div>
        <div class="col-lg-10">
          <h6 class="text-center">
            <ul class="list-group">  
               <li class="list-group-item text-justify"> Adicionalmente garantizamos un gran impulso para su negocio, con un margen estimado de utilidad entre
un 15% y 25% en sus ganancias.
              </li>
            </ul>
          </h6> 
      </div>
    <div class="col-lg-1"></div>
</div>

<div class="row">
  <div class="col-lg-1"></div>
  <div class="col-lg-10"> <h6 class="text-center" >
<ul class="list-group">

  <li class="list-group-item"> <h3>BENEFICIOS:</h3></li>
  <li class="list-group-item">Precio preferencial de mayoreo.</li>
  <li class="list-group-item">Promociones que generan un margen de utilidad de hasta un 45%.</li>
  <li class="list-group-item">Material impreso (carteles y preciadores).</li>
  <li class="list-group-item">El producto es entregado en la puerta de su negocio por nuestros vendedores.</li>
  <li class="list-group-item">Concursos por incremento de ventas.</li>
  <li class="list-group-item">Apoyo con degustaciones en su negocio. (1er compra)</li>
  <li class="list-group-item">Material promocional para sus clientes</li>
</ul>
 </h6> </div>
  <div class="col-lg-1"></div>
  <hr style="background-color: #FA3910;width:100%;">

</div>


       

        

