import { Component, OnInit } from '@angular/core';
import { ApisService} from 'src/app/service/apis.service';
  import { from } from 'rxjs';
import { Listas } from 'src/app/models/listas';
import { JsonPipe } from '@angular/common';

@Component({
  selector: 'app-precios',
  templateUrl: './precios.component.html',
  styleUrls: ['./precios.component.css']
})
export class PreciosComponent implements OnInit {

  public listas:any;

  constructor( private apiservice: ApisService) { }

  ngOnInit(): void {

    this.apiservice.getListas().subscribe(listas=> {
      console.log(listas);
      console.log("aqui")

      this.listas=listas;
    })

  }

}
