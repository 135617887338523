<section class="row">
    <hr style="background-color: #E2432D;width:100%;">
   
     <br>&nbsp;<br>
   </section>
     <div class="row" >
          <div class="col-md-4 promocion">
     <div class="thumbnail">
               <img  class="img-fluid" src="assets/img/emanuel/Jamon-York-two.jpg" alt="">
                 <div class="caption">
                   <br>
                   <h3 style="color:#D5342A; font-weight: bold;">Jamón mismo tipo</h3>
                   <p>Compra 5 jamones del mismo tipo y llevate GRATIS 1 jamón del mismo tipo </p>
                  
               </div>
             </div>
           
           </div>
       
           <div class="col-md-4 promocion">
     <div class="thumbnail">
               <img  class="img-fluid" src="assets/img/emanuel/Jamon-york-one.jpg" alt="">
                 <div class="caption">
                   <br>
                   <h3 style="color:#D5342A; font-weight: bold;">Jamón 2.5</h3>
                   <p>Compra 2.5 jamones de un mismo tipo y llevate GRATIS 1/2 jamón  del mismo tipo</p>
                  
               </div>
             </div>
           
           </div>
   
           <div class="col-md-4 promocion">
             <div class="thumbnail">
                   <img  class="img-fluid" src="assets/img/emanuel/Jamon-americano-one.jpg" alt="">
                 <div class="caption">
                   <br>
                   <h3 style="color:#D5342A; font-weight: bold;">Mix</h3>
                   <p>Compre 1 pieza de queso de puerco + 1 pieza de jamón americano + 1 pieza de jamón york + 1 pieza de jamón de pavo + 1 pieza de jamón horneado y llevate GRATIS 1 jamón horneado</p>
                  
               </div>
             </div>
           </div>
           
       
     </div>
   <div class="row">
   
           <div class="col-md-2 promocion"></div>
          <div class="col-md-4 promocion">
             <div class="thumbnail">
               <img  class="img-fluid" src="assets/img/emanuel/chuleta-one.jpg" alt="">
                 <div class="caption">
                   <br>
                   <h3 style="color:#D5342A; font-weight: bold">CHULETA</h3>
   
                   
                     <ul>
                       <li>Compra 5 kg de chuleta ahumada marcada o en charola y llevate GRATIS 1 kg de longaniza, (solo aplica promoción, si el producto es comercializado).</li>
                     </ul>
                
               </div>
             </div>
           </div>
               <div class="col-md-4 promocion">
             <div class="thumbnail">
               <img  class="img-fluid" src="assets/img/emanuel/Longaniza.jpg" alt="">
                 <div class="caption">
                   <br>
                   <h3 style="color:#D5342A; font-weight: bold;">Longaniza 60</h3>
   
                   
                     <ul>
                       <li>Compra 1 pza de Jamón y llevate 2 kg de longaniza a $60</li>
                       <li>Compra 2 pzas de Jamón y llevate 5 kg de longaniza a $60</li>
                       <li>Compra 3 pzas de Jamón y llevate 7 kg de longaniza a $60</li>
                     </ul>
                
               </div>
             </div>
           </div>
   
      
   
       
           
     </div>
       
      
   
   
   <section class="row">
     <br>&nbsp;<br>
   
   </section>
   <div class="row">
    <div class="col-lg-1"></div>
        <div class="col-lg-10">
            <h3 class="text-center">
            *Las promociones aplican, solo si los productos son comercializados. 
           </h3>
      </div>
    <div class="col-lg-1"></div>
</div>