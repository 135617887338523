import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavComponent } from './component/nav/nav.component';
import { FooterComponent } from './component/footer/footer.component';
import { BodyComponent } from './component/body/body.component';
import { QuienesSomosComponent } from './component/quienes-somos/quienes-somos.component';
import { InicioComponent } from './component/inicio/inicio.component';
import { GaleriaComponent } from './component/galeria/galeria.component';
import { ContactoComponent } from './component/contacto/contacto.component';
import { JamonYorkComponent } from './component/productos/jamon-york/jamon-york.component';
import { JamonDePavoComponent } from './component/productos/jamon-de-pavo/jamon-de-pavo.component';
import { JamonHorneadoComponent } from './component/productos/jamon-horneado/jamon-horneado.component';
import { JamonTipoAmericanoComponent } from './component/productos/jamon-tipo-americano/jamon-tipo-americano.component';
import { QuesoDePuercoKamiComponent } from './component/productos/queso-de-puerco-kami/queso-de-puerco-kami.component';
import { TocinoComponent } from './component/productos/tocino/tocino.component';
import { LonganizaComponent } from './component/productos/longaniza/longaniza.component';
import { ChuletaAhumadaComponent } from './component/productos/chuleta-ahumada/chuleta-ahumada.component';
import { CarneAlPastorComponent } from './component/productos/carne-al-pastor/carne-al-pastor.component';
import { ChorizoArgentinoComponent } from './component/productos/chorizo-argentino/chorizo-argentino.component';
import { PromocionesComponent } from './component/mayoristas/promociones/promociones.component';
import { PreciosComponent } from './component/mayoristas/precios/precios.component';
import { BeneficiosComponent } from './component/mayoristas/beneficios/beneficios.component';
import { FormsModule}from '@angular/forms';
@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    FooterComponent,
    BodyComponent,
    QuienesSomosComponent,
    InicioComponent,
    GaleriaComponent,
    ContactoComponent,
    JamonYorkComponent,
    JamonDePavoComponent,
    JamonHorneadoComponent,
    JamonTipoAmericanoComponent,
    QuesoDePuercoKamiComponent,
    TocinoComponent,
    LonganizaComponent,
    ChuletaAhumadaComponent,
    CarneAlPastorComponent,
    ChorizoArgentinoComponent,
    PromocionesComponent,
    PreciosComponent,
    BeneficiosComponent,
    
   
    


    
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
