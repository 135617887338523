import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-longaniza',
  templateUrl: './longaniza.component.html',
  styleUrls: ['../../../../assets/css/descripcion-productos.css']
})
export class LonganizaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
