import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser'

@Component({
  selector: 'app-quienes-somos',
  templateUrl: './quienes-somos.component.html',
  styleUrls: ['./quienes-somos.component.css']
})
export class QuienesSomosComponent implements OnInit {
  title = 'Kami quienes somos';


  constructor(private metaTagService: Meta,
    private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.addTags([
      {
        name: 'description', content: '    KAMI es una empresa familiar mexicana con 5 años' +
          ' en el mercado, ubicada al sur de la Ciudad de México.' +
          ' dedicada al procesamiento de carne de cerdo para' +
          ' la obtención de productos de valor agregado con un alto aporte nutricional'
      },
      {
        name: 'keywords', content: 'Jamón artesanal, Jamón bajo en conservadores, Marca de Jamón en México,' +
          ' Marca de Jamón Mexicana, Jamóm Mexicano, Embutidos Mexicanos'
      },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Kami' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'date', content: '2020-08-07', scheme: 'YYYY-MM-DD' },
      { charset: 'UTF-8' }
    ]);
  }

}
