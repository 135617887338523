import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BodyComponent } from './component/body/body.component';
import { QuienesSomosComponent } from './component/quienes-somos/quienes-somos.component';
import { InicioComponent } from './component/inicio/inicio.component';
import { GaleriaComponent } from './component/galeria/galeria.component';
import { ContactoComponent } from './component/contacto/contacto.component';
import { JamonYorkComponent } from './component/productos/jamon-york/jamon-york.component';
import { CarneAlPastorComponent } from './component/productos/carne-al-pastor/carne-al-pastor.component';
import { JamonDePavoComponent } from './component/productos/jamon-de-pavo/jamon-de-pavo.component';
import { JamonHorneadoComponent } from './component/productos/jamon-horneado/jamon-horneado.component';
import { JamonTipoAmericanoComponent } from './component/productos/jamon-tipo-americano/jamon-tipo-americano.component';
import { QuesoDePuercoKamiComponent } from './component/productos/queso-de-puerco-kami/queso-de-puerco-kami.component';
import { TocinoComponent } from './component/productos/tocino/tocino.component';
import { LonganizaComponent } from './component/productos/longaniza/longaniza.component';
import { ChuletaAhumadaComponent } from './component/productos/chuleta-ahumada/chuleta-ahumada.component';
import { ChorizoArgentinoComponent } from './component/productos/chorizo-argentino/chorizo-argentino.component';
import { PreciosComponent } from './component/mayoristas/precios/precios.component';
import { PromocionesComponent } from './component/mayoristas/promociones/promociones.component';
import { BeneficiosComponent } from './component/mayoristas/beneficios/beneficios.component';



const routes: Routes = [
  { path: '', component: InicioComponent},
  { path: 'quienes-somos', component: QuienesSomosComponent },
  { path: 'inicio', component: InicioComponent},
  {path: 'galeria', component: GaleriaComponent},
  {path: 'contacto', component: ContactoComponent},
  {path: 'jamon-york', component: JamonYorkComponent},
  {path: 'carne-al-pastor', component: CarneAlPastorComponent},
  {path: 'jamon-de-pavo', component: JamonDePavoComponent},
  {path: 'jamon-horneado', component: JamonHorneadoComponent},
  {path: 'jamon-tipo-americano', component: JamonTipoAmericanoComponent},
  {path: 'queso-de-puerco-kami', component: QuesoDePuercoKamiComponent },
  {path: 'tocino', component: TocinoComponent},
  {path: 'longaniza', component: LonganizaComponent},
  {path: 'chuleta-ahumada', component: ChuletaAhumadaComponent},
  {path: 'chorizo-argentino', component: ChorizoArgentinoComponent},
  {path: 'precios', component: PreciosComponent },
  {path: 'promociones', component: PromocionesComponent},
  {path: 'beneficios', component: BeneficiosComponent},
  { path: '**', component: InicioComponent},
  
 ];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

