<div class="row">

  <div id="myCarousel" class="carousel slide carousel-fade " data-ride="carousel" style="width:100%">
    <ol class="carousel-indicators">
      <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
      <li data-target="#myCarousel" data-slide-to="1" class=""></li>
      <li data-target="#myCarousel" data-slide-to="2" class=""></li>
      <li data-target="#myCarousel" data-slide-to="3" class=""></li>
      <li data-target="#myCarousel" data-slide-to="4" class=""></li>
      <li data-target="#myCarousel" data-slide-to="5" class=""></li>


    </ol>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <a routerLink="/galeria" role="">
          <img class=" img-fluid w-100 " src="assets/img/productos_kami.png" style="max-height: 610px"
            alt="First slide">
        </a>
      </div>

      <div class="carousel-item ">
        <a routerLink="/promociones" role="">
          <img class=" img-fluid w-100 " src="assets/img/tocino.png" style="max-height: 610px" alt="First slide">
        </a>

        <!--<div class="carousel-caption text-left ">
          <div class="d-none d-md-block">
            <h1 class="caption-text d-inline">Promoción</h1>
            <br>
            <h1 class="caption-text d-inline">JAMÓN MISMO TIPO</h1>
            <br>

            <p class="caption-text d-inline">En la compra de 5 jamones de un mismo tipo reciba un sexto gratis.</p>
          </div>
          <p><a class="btn caption-text " routerLink="/promociones" role="button">Ver mas...</a></p>
        </div> -->
      </div>

      <div class="carousel-item">
        <a routerLink="/promociones" role="">
          <img class="w-100 " src="assets/img/chorizo.png" style="max-height: 610px" alt="First slide">
        </a>
        <!--<div class="carousel-caption text-left">
          <div class="d-none d-md-block">
            <h1 class="caption-text d-inline">Promoción</h1>
            <br>
            <h1 class="caption-text d-inline">MIX</h1>
            <br>
            <p class="caption-text d-inline">En la compra de 5 barras de cualquier presentación reciba un jamón horneado
              gratis.</p>
          </div>
          <p><a class="btn caption-text " routerLink="/promociones" role="button">Ver mas...</a></p>
        </div>-->

      </div>

      <div class="carousel-item">
        <a routerLink="/promociones" role="">
          <img class="w-100 " src="assets/img/pastor.png" style="max-height: 610px" alt="First slide">
        </a>
        <!--<div class="carousel-caption text-left">
          <div class="d-none d-md-block">
            <h1 class="caption-text d-inline">Promoción</h1>
            <br>
            <h1 class="caption-text d-inline">LONGANIZA 60</h1>
            <br>
            <p class="caption-text d-inline">En la compra de 1 pieza de jamón podrá adquirir hasta 2 kg de longaniza a
              precio de promoción($60) del mismo tipo.</p>
          </div>
          <p><a class="btn caption-text " routerLink="/promociones" role="button">Ver mas...</a></p>
        </div>-->

      </div>

      <div class="carousel-item ">
        <a routerLink="/promociones" role="">
          <img class="w-100" src="assets/img/jamon2.5.png" style="max-height: 610px" alt="First slide">
        </a>
        <!--<div class="carousel-caption  text-left">
          <div class="d-none d-md-block">
            <h1 class="caption-text d-inline">Promoción</h1>
            <br>
            <h1 class="caption-text d-inline ">JAMON 2.5</h1>
            <br>
            <p class="caption-text d-inline">Compra 2.5 jamones de un mismo tipo y recibe GRATIS 1/2 jamón del mismo
              tipo</p>
          </div>
          <p><a class="btn caption-text " routerLink="/promociones" role="button">Ver mas...</a></p>
        </div>-->
      </div>

      <div class="carousel-item ">
        <a routerLink="/promociones" role="">
          <img class="w-100" src="assets/img/chuleta.png" style="max-height: 610px" alt="First slide">
        </a>
        <!--<div class="carousel-caption  text-left">
          <div class="d-none d-md-block">
            <h1 class="caption-text d-inline">Promoción</h1>
            <br>
            <h1 class="caption-text d-inline ">CHULETA</h1>
            <br>
            <p class="caption-text d-inline">Compra 5 kg de chuleta ahumada marcada o en charola y recibe GRATIS 1 kg de
              longaniza </p>
          </div>
          <p><a class="btn caption-text " routerLink="/promociones" role="button">Ver mas...</a></p>
        </div>-->
      </div>


    </div>
    <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon " aria-hidden="true"></span>
      <span class="sr-only">comcasa</span>
    </a>
    <a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">kami</span>
    </a>
  </div>

</div>


<section class="row-fluid">
  <pre>

      </pre>
  <h1>
    <div class="col-md-12" style=" font-family: 'Montserrat', sans-serif;color:#D93630;">Calidad con sabor
      artesanal:</div>
  </h1>
  <pre>
            </pre>
  <!--Carousel Wrapper-->
  <div id="multi-item-example" class="carousel slide carousel-multi-item" data-ride="carousel">


    <!--/.Controls-->

    <!--Indicators-->
    <ol class="carousel-indicators">
      <li data-target="#multi-item-example" data-slide-to="0" class="active"></li>
      <li data-target="#multi-item-example" data-slide-to="1"></li>
      <li data-target="#multi-item-example" data-slide-to="2"></li>
    </ol>
    <!--/.Indicators-->

    <!--Slides-->

    <div class="carousel-inner" role="listbox">

      <!--First slide-->
      <div class="carousel-item active">
        <div class="row">
          <div class="col-md-3">
            <div class="card">
              <img class="img-fluid" src="assets/img/emanuel/Jamon-York-two.jpg" alt="jamon york kami">
              <div class="card-body">
                <h4 class="card-title">Jamón York</h4>
                <p><a class="btn caption-text " routerLink="/jamon-york" role="button">Ver mas...</a></p>
              </div>
            </div>
          </div>

          <div class="col-md-3 ">
            <div class="card">
              <img class="img-fluid" src="assets/img/eutiquio/Jamon-de-pavo-two.jpg" alt="jamon de pavo kami">
              <div class="card-body">
                <h4 class="card-title">Jamón de Pavo</h4>
                <p><a class="btn caption-text " routerLink="/jamon-de-pavo" role="button">Ver mas...</a></p>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="card">
              <img class="img-fluid" src="assets/img/eutiquio/Jamon-horneado-one.jpg" alt="jamon horneado">
              <div class="card-body">
                <h4 class="card-title">Jamón Horneado</h4>
                <p><a class="btn caption-text " routerLink="/jamon-horneado" role="button">Ver mas...</a></p>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="card">
              <img class="img-fluid" src="assets/img/emanuel/Jamon-americano-one.jpg" alt="jamon americano">
              <div class="card-body">
                <h4 class="card-title">Jamón Americano</h4>
                <p><a class="btn caption-text " routerLink="/jamon-tipo-americano" role="button">Ver mas...</a></p>
              </div>
            </div>
          </div>




        </div>
      </div>
      <!--/.First slide-->

      <!--Second slide-->
      <div class="carousel-item">
        <div class="row">
          <div class="col-md-3">
            <div class="card">
              <img class="img-fluid" src="assets/img/queso-de-puerco-two.jpg" alt="queso de puerco">
              <div class="card-body">
                <h4 class="card-title">Queso de Puerco</h4>
                <p><a class="btn caption-text " routerLink="/queso-de-puerco-kami" role="button">Ver mas...</a></p>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card">
              <img class="img-fluid" src="assets/img/emanuel/Tocino-rebanado-one.jpg" alt="tocino de alta calidad">
              <div class="card-body">
                <h4 class="card-title">Tocino</h4>
                <p><a class="btn caption-text " routerLink="/tocino" role="button">Ver mas...</a></p>

              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="card">
              <img class="img-fluid" src="assets/img/emanuel/Longaniza-two.jpg" alt="longaniza de alta calidad">
              <div class="card-body">
                <h4 class="card-title">Longaniza</h4>
                <p><a class="btn caption-text " routerLink="/longaniza" role="button">Ver mas...</a></p>
              </div>
            </div>
          </div>

          <div class="col-md-3 ">
            <div class="card">
              <img class="img-fluid" src="assets/img/eutiquio/Chuleta-ahumada-one.jpg" alt="chuleta ahumada">
              <div class="card-body">
                <h4 class="card-title">Chuleta Ahumada</h4>

                <p><a class="btn caption-text " routerLink="/chuleta-ahumada" role="button">Ver mas...</a></p>
              </div>
            </div>
          </div>


        </div>
      </div>
      <!--/.Second slide-->

      <!--Third slide-->
      <div class="carousel-item">
        <div class="row">

          <div class="col-md-3">

          </div>


          <div class="col-md-3">
            <div class="card">
              <img class="img-fluid" src="assets/img/pastor_one.jpg" alt="carne al pastor de lata calidad">
              <div class="card-body">
                <h4 class="card-title">Carne al Pastor</h4>

                <p><a class="btn caption-text " routerLink="/carne-al-pastor" role="button">Ver mas...</a></p>
              </div>
            </div>
          </div>


          <div class="col-md-3">
            <div class="card">
              <img class="img-fluid" src="assets/img/eutiquio/Cho_arg_one.jpg" alt="chorizo argentino de alta calidad">
              <div class="card-body">
                <h4 class="card-title">Chorizo Argentino</h4>
                <p><a class="btn caption-text " routerLink="/chorizo-argentino" role="button">Ver mas...</a></p>
              </div>
            </div>
          </div>

          <div class="col-md-3">

          </div>



        </div>
      </div>
      <!--/.Third slide-->
      <section class="row">
        <br>&nbsp;
      </section>

      <a class="carousel-control-prev row-fluid" href="#multi-item-example" role="button" data-slide="prev">
        <i class="fa fa-chevron-left fa-2x" aria-hidden="true" style="color:#076330;"></i>

        <span class="sr-only">comcasa</span>
      </a>
      <a class="carousel-control-next" href="#multi-item-example" role="button" data-slide="next">
        <i class="fa fa-chevron-right fa-2x" aria-hidden="true" style="color:#076330;"></i>
        <span class="sr-only">kami</span>
      </a>


    </div>



    <!--/.Slides-->


  </div>
  <!--/.Carousel Wrapper-->


</section>