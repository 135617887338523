import { Injectable } from '@angular/core';
import { Contacto } from 'src/app/models/contacto';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class ContactoService {
  public url:string;
  constructor(private _http:HttpClient )  { 
  }


  create( contacto:Contacto):Observable<any>{

    this.url='http://bk.kami-comcasa.com.mx/public/index.php/api/';
    let json = JSON.stringify(contacto);
    let params = "json="+json;
    let headers = this.headers(); 
    return this._http.post(this.url + 'view',params ,{headers:headers});
  }

  


  headers(token = null){
    let valheaders;
    if(token != null){
      valheaders = new HttpHeaders().set('Content-Type' ,'application/x-www-form-urlencoded')
      .set('Authorization',token);
    }else{
      valheaders = new HttpHeaders().set('Content-Type' ,'application/x-www-form-urlencoded')

    }

    return valheaders;
   
  
  }
  
}