import { Component, OnInit } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser'


@Component({
  selector: 'app-promociones',
  templateUrl: './promociones.component.html',
  styleUrls: ['./promociones.component.css']
})
export class PromocionesComponent implements OnInit {
  title = 'Kami promociones';


  constructor(    private metaTagService: Meta,
    private titleService:Title) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.addTags([
      { name: 'description', content:'    KAMI es una empresa familiar mexicana con 5 años'+
      ' en el mercado, ubicada al sur de la Ciudad de México.'+
       ' dedicada al procesamiento de carne de cerdo para'+
       ' la obtención de productos de valor agregado con un alto aporte nutricional'},
      { name: 'keywords', content: 'Embutidos Mayoreo, venta de Jamón Mayoreo, Barra De Jámon ,'+ 
      'Jamón por mayoreo, Jamón economico, Queso de Puerco' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Kami' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'date', content: '2020-08-07', scheme: 'YYYY-MM-DD' },
      { charset: 'UTF-8' }
    ]);
  }

}