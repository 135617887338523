import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-jamon-tipo-americano',
  templateUrl: './jamon-tipo-americano.component.html',
  styleUrls: ['../../../../assets/css/descripcion-productos.css']
})
export class JamonTipoAmericanoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
