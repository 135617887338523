<hr style="background-color: #1D2549;">
  <div class="row">
    <div class="col-lg-1"></div>
    <div class="col-lg-4 ">
<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="galeria.html" style="color:white;" >  Producto Artesanal</a></li>
    <li class="breadcrumb-item active" aria-current="page"> Tocino</li>
  </ol>
</nav>

     <div class="img-thumbnail">
             <img src="assets/img/emanuel/Tocino-rebanado-one.jpg" class="img-fluid">

     </div>
    </div>
    <div class="col-lg-6  ">
      <ul class="nav nav-pills mb-3 link-tabs nav-tabs" id="pills-tab"
        role="tablist">
        <li class="nav-item  "><a class="nav-link active "
          id="pills-home-tab" data-toggle="pill" href="#pills-home"
          role="tab" aria-controls="pills-home" aria-selected="true">Descripción
            <p>del producto</p>
        </a></li>
        <li class="nav-item"><a class="nav-link" id="pills-profile-tab"
          data-toggle="pill" href="#pills-profile" role="tab"
          aria-controls="pills-profile" aria-selected="false">Presentación
            <p class="invisible">de</p>
        </a></li>
        <li class="nav-item"><a class="nav-link" id="pills-info-tab"
          data-toggle="pill" href="#pills-info" role="tab"
          aria-controls="pills-info" aria-selected="false">Información
            <p>nutrimental</p>
        </a></li>
        <li class="nav-item"><a class="nav-link text-center"
          id="pills-diario-tab" data-toggle="pill" href="#pills-diario"
          role="tab" aria-controls="pills-diario" aria-selected="false">Aporte <p>nutricional</p>
        </a></li>
        <li class="nav-item"><a class="nav-link" id="pills-produc-tab"
          data-toggle="pill" href="#pills-produc" role="tab"
          aria-controls="pills-produc" aria-selected="false">Manejo del
            <p>producto</p>
        </a></li>
      </ul>

      

      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <td>
          <p>Producto elaborado artesanalmente a partir de
              la falda del cerdo condimentado y sometido a 
              un proceso de cocción que le proporciona un 
              sabor incomparable. 
          Perfil sensorial</p>
          <ol>Textura: firme, resistente al corte
          </ol>
          <ol>Olor: tenue, ahumado 
          </ol>
          <ol>Sabor: Jugoso grasoso, ligeramente salado 
          </ol>
          <ol>Color: rosa y blanco 
          </ol>
          
          </td>
          </tr>
          </tbody>
          </table>
        </div>
        <div class="tab-pane fade" id="pills-profile" role="tabpanel"
          aria-labelledby="pills-profile-tab">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <td><h4>Presentacion</h4></td>
              </tr>
              <tr>
                <td>Rebanado 250 gr. Al vacío </td>
              </tr>
              <tr>
                  <td>Barra </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="tab-pane fade" id="pills-info" role="tabpanel"
          aria-labelledby="pills-info-tab">
          <table class="table table-bordered col-lg-8">
            <thead>
              <tr>
                <th colspan="2" class="text-center">Informacion nutrimental
                </th>
              </tr>
              <tr>
                <th colspan="2" class="text-center">tamaño de porcion 100gr</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Contenido energetico</td>
                <td>226 Kcal</td>
              </tr>
              <tr>
                <td>Proteínas</td>
                <td>3g</td>
              </tr>
              <tr>
                <td>Lípidos</td>
                <td>3.5g</td>
              </tr>
              <tr>
                <td>Grasas saturadas</td>
                <td>3.5g</td>
              </tr>
              <tr>
                <td>Hidratos de carbomo</td>
                <td>0.3g</td>
              </tr>
              <tr>
                <td>Azúcares</td>
                <td>0g</td>
              </tr>
              <tr>
                <td>Fibra dietética</td>
                <td>0mg</td>
              </tr>
              <tr>
                <td>Sodio</td>
                <td>232mg</td>
              </tr>
              <tr>
                <td>Otras grasas</td>
                <td>15Kcal</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="tab-pane fade" id="pills-diario" role="tabpanel"
          aria-labelledby="pills-diario-tab">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <td colspan="5"><h4 class="text-center">Una porcion de
                    100g aporta</h4></td>
              </tr>
              <tr>
                <td><p>Energía</p> 226 Kcal</td>
                <td><p>Azúcares</p> 0 Kcal</td>
                <td><p>Otras grasas</p> 15 Kcal</td>
                <td><p>Grasas saturadas</p> 31 kcal</td>
                <td><p>Sodio</p> 232mg</td>
              </tr>
              <tr>
                <td><p>11%</p> Cal/Kcal</td>
                <td>0%</td>
                <td>8%</td>
                <td>18%l</td>
                <td>23%</td>
              </tr>
              <tr>
                <td colspan="5"><h4 class="text-center">*%Diario
                    basado en una dieta de 2000Kcal</h4></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="tab-pane fade" id="pills-produc" role="tabpanel"
          aria-labelledby="pills-produc-tab">
          <table class="table table-bordered">
            <th>
          <h6>
            <ol>Conservar en refrigeración (0-4°C) hasta su consumo. 
            </ol>
            <ol>Consumo preferente antes de la fecha de caducidad. 
            </ol>
            <ol>Vida de anaquel: 60 días 
            </ol>
            <ol>Consumir bien cocido. 
            </ol>
          </h6>
          </th>
          </table>
        </div>
      </div>
    </div>
    <!-- HTML -->
  </div>

  <hr style="background-color: #1D2549;">