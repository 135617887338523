
<div class="row">
  <hr style="background-color: #FA3910;width:100%;">

  <div class="col-lg-1 col-sm-1 "></div>
  <div class="col-lg-10 col-sm-6">

    <ol id="lista3">

      <table class="table ">
        <thead>
          <tr>
            <th scope="col" id="lista2">
              <ul> Peso o Presentacion </ul>
            </th>
            <th scope="col" id="lista2">
              <ul> Producto</ul>
            </th>
            <th scope="col" id="lista2">
              <ul>Precio Mayoreo</ul>
            </th>

          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of listas">
            <td>
              <li>{{item.cantidad }}{{item.unidad}} {{item.rules}}</li>
            </td>
            <td>
              <li>{{item.producto}}</li>
            </td>
            <td>
              <li>${{item.precio}}</li>
            </td>
          </tr>


        </tbody>
      </table>

    </ol>

  </div>
  <div class="col-lg-1 col-sm-1"></div>
</div>


<div class="row">
  <div class="col-lg-3"></div>
  <div class="col-lg-6">
    <h6 class="text-center" style="color:red">*Precio promedio de 3.5kg 5.5 kg depende del tipo de jamón </h6>
  </div>
  <div class="col-lg-3"></div>
</div>

<div class="row">
  <div class="col-lg-3"></div>
  <div class="col-lg-6">
    <h6 class="text-center" style="color:red">**Acorde al peso del producto</h6>
  </div>
  <div class="col-lg-3"></div>
</div>

<div class="row">
  <div class="col-lg-3"></div>
  <div class="col-lg-6">
    <h6 class="text-center" style="color:red">***Compra minima 3kg</h6>
  </div>
  <div class="col-lg-3"></div>
</div>



<div class="row">
  <pre>

    </pre>
  <hr style="background-color: #FA3910;width:100%;">

</div>