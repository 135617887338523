import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class ApisService {
  public url:string;
  constructor(private _http:HttpClient )  { 
  }


  getListas( ):Observable<any>{

    this.url='http://bk.kami-comcasa.com.mx/public/index.php/api/';
    let headers = this.headers(); 
    return this._http.get(this.url + 'listasMayoristas',{headers:headers});
  }

  


  headers(token = null){
    let valheaders;
    if(token != null){
      valheaders = new HttpHeaders().set('Content-Type' ,'application/x-www-form-urlencoded')
      .set('Authorization',token);
    }else{
      valheaders = new HttpHeaders().set('Content-Type' ,'application/x-www-form-urlencoded')

    }

    return valheaders;
   
  
  }
  
}