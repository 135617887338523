<div class="row">
    <hr style="background-color: #FA3910;width:100%;">


    <div class="col-lg-12 col-xs">

        <img class="img-fluid" src="assets/img/redondos/ro.jpg">

    </div>
</div>




<hr style="background-color: #FC1F0D;">

<div class="row">
    <div class="col-lg-1"></div>
    <div class="col-lg-10">
        <ul
            class="nav nav-tabs nav-pills mb-3 nav-justified justify-content-center  flex-column flex-sm-row"
            id="pills-tab" role="tablist">
            <li class="nav-item"><a class="nav-link active"
                id="pills-home-tab" data-toggle="pill" href="#pills-home"
                role="tab" aria-controls="pills-home" aria-selected="true">Quiénes
                    somos</a></li>
            <li class="nav-item"><a class="nav-link" id="pills-his-tab"
                data-toggle="pill" href="#pills-his" role="tab"
                aria-controls="pills-his" aria-selected="false">Historia de la marca</a>
            <li class="nav-item"><a class="nav-link" id="pills-mis-tab"
                data-toggle="pill" href="#pills-mis" role="tab"
                aria-controls="pills-mis" aria-selected="false">Misión</a></li>
            <li class="nav-item"><a class="nav-link" id="pills-vis-tab"
                data-toggle="pill" href="#pills-vis" role="tab"
                aria-controls="pills-vis" aria-selected="false">Visión</a></li>
            <li class="nav-item"><a class="nav-link" id="pills-val-tab"
                data-toggle="pill" href="#pills-val" role="tab"
                aria-controls="pills-val" aria-selected="false">Valores</a></li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
           

            <div class="tab-pane fade show active acc" id="pills-home"
                role="tabpanel" aria-labelledby="pills-home-tab">
            <div class="row">
                <div class="col-lg-3"></div>
                <div class="col-lg-6">
                    <h4> <p class="text-justify h33 "> KAMI es una empresa familiar mexicana con 5 años en el mercado, ubicada al sur de 
                        la Ciudad de México, dedicada al procesamiento de carne de cerdo para la obtención de productos de valor agregado con un alto aporte nutricional.</p></h4>
                        <h4>  <p class="text-justify h33">KAMI cuenta con una variedad de productos de valor agregado, : jamón horneado, tipo york, americanoy pavo, además, tocino ahumado, queso de puerco, chuleta ahumada, longaniza, carne al pastor, y chorizo argentino; todos los productos obtenidos son elaborados de manera artesanal, cuidando en todo momento su calidad e inocuidad.</p></h4>
                
                
                    
                </div>
                <div class="col-lg-3"></div>

            </div>

        </div>





              <div class="tab-pane fade acc" id="pills-his" role="tabpanel"
                aria-labelledby="pills-his-tab" >
                <div class="row">
                 <div class="col-lg-2"></div>


                <div class="col-lg-8">
                    <h4>
                <div>
                    <p class="text-justify h33">En el año 2000 “Carnes Salas”, se consolidó legalmente como una empresa “COMERCIALIZADORA DE CARNES SALAS, S.A. DE C.V.” <sup style="font-size:10px;">MR</sup>, siendo la experiencia, los clientes, la visión familiar y empresarial, lo que dio paso a la formalidad, dejando atrás un negocio con viejas prácticas, para entrar a una etapa de crecimiento y consolidación, y establecerse como PYME familiar 100% mexicana, orgullosamente Xochimilca, conformada por una familia que afrontó un camino lleno de obstáculos y sacrificios, para aportar a la sociedad un producto de alta calidad.
                    </p>
                </div>

                <hr style="background-color: #015121;">
                <div>
                    <p class="text-justify h33">Después del camino recorrido para la consolidación, pretendemos continuar como una empresa de gente emprendedora y de bien, llevando el firme propósito de seguir “con el orgullo y la misma visión de crecimiento y reconocimiento que nos ha traído hasta aquí”.</p>
                </div>



                <hr style="background-color: #015121;">
                <div>
                    <p class="text-justify h33">Una vez constituida Comercializadora de Carnes Salas, S.A. de C.V. <sup style="font-size:10px;">MR</sup>, se presentaron problemas de falta de pago de embutidores de jamón y fritureros, a los que se les vendían productos como pierna de cerdo, lomo americano, grasa, cuero, y retazo. Poniéndole siempre peros a los productos con la finalidad de pagarlos a crédito y más baratos. Por lo que se visualizó el área de oportunidad y se toma la decisión de comenzar a elaborar nuestros productos de manera artesanal a baja escala, así fue como se empezó a incursionar en una nueva división de negocio montando una pequeña empacadora. Con una unidad vehicular llamada “vochoneta”, se lanza al mercado de carnicerías y tiendas de conveniencia a comercializar chuleta ahumada, manteca, chicharrón prensado, los cuales fueron nuestros primeros productos.</p>
                </div>

                <hr style="background-color: #015121;">
                <div>
                    <p class="text-justify h33">Cómo se obtuvo una buena respuesta, en los siguientes años se amplió la gama de productos incorporando jamones, tocino, longaniza, carne al pastor, queso de puerco. Es en el año de 2016 cuando se registra la marca “KAMI” <sup style="font-size:10px;">MR</sup>, como la división de productos cárnicos procesados, los que hasta hoy en día son llevados a los hogares mexicanos a través de nuestros vendedores de cambaceo.</p>
                </div>
            </h4>
            </div>

                <div class="col-lg-2"></div>
            </div>
            

        




              

            </div>


                <div class="tab-pane fade" id="pills-mis" role="tabpanel"
                aria-labelledby="pills-mis-tab">
                <div class="row">
                    <div class="col-lg-4"></div>
                    <div class="col-lg-4">
                        <h4>
                        <p class="text-justify acc h33">Somos la marca que elabora y distribuye productos cárnicos procesados, con los mejores ingredientes en forma artesanal y de excelente calidad en el mercado, para garantizar un alto valor nutricional a los consumidores.</p>
                        </h4>                        
                    </div>
                    <div class="col-lg-4"></div>

                </div>

            </div>


        <div class="tab-pane fade" id="pills-vis" role="tabpanel"
                aria-labelledby="pills-vis-tab">
                <div class="row">
                    <div class="col-lg-4"></div>
                    <div class="col-lg-4">
                        <h4>
                        <p class="text-justify acc h33">Ser la marca reconocida en el mercado, por ofrecer un producto cárnico procesado que aporta salud y bienestar a sus consumidores mediante el uso de conservadores naturales.
                        </p>
                        </h4> 
                    </div>
                    <div class="col-lg-4"></div>
                </div>

            </div>

    
    
        <div class="tab-pane fade" id="pills-val" role="tabpanel"
                aria-labelledby="pills-val-tab">

                
                <div class="row">
                    <div class="col-lg-1"> </div>
                    <div class="col-lg-5 h33" text-center>
                        <h4>
                            <p class="text-justify">Respeto</p>
                            <p class="text-left">Lealtad</p>
                            <p class="text-left">Honradez</p>
                            <p class="text-left">Compromiso</p>
                            
                        </h4>
                    </div>
                    <div class="col-lg-5  text-center acc h33 ">
                        <h4> 
                        <p class="text-left">Disciplina</p>
                        <p class="text-left">Responsabilidad</p>
                        <p class="text-left">Autocritica</p>
                        <p class="text-left">Disponibilidad al cambio</p>
                    </h4>
                    </div>
                    <div class="col-lg-1"> </div>
                </div>
            </div>

        </div>
        <div class="col-lg-1"></div>
        <pre>
       </pre>
    </div>
    <hr style="background-color: #FA3910;width:100%;">

</div>