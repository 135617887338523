
<div class="container-fluid">

<div class="row-social">
  
</div>



  <div class="row">
    <div class="col-lg-2 "></div>
    <div class="col-lg-8 ">
    
    
    <MARQUEE scrollamount="12" style=" width: 90%; ">
    <FONT class="flayer">
    CALIDAD    
    </FONT>
    <FONT class="fly">
    CON  SABOR 
    </FONT>
    <FONT class="flex">
    ARTESANAL
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </FONT>
    <FONT class="flayer">
      CALIDAD 
      
      </FONT>
      <FONT class="fly">
      CON  SABOR 
   
      </FONT>
      <FONT class="flex">
      ARTESANAL
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </FONT>
      <FONT class="flayer">
        CALIDAD 
        
        </FONT>
        <FONT class="fly">
        CON  SABOR 
     
        </FONT>
        <FONT class="flex">
        ARTESANAL
    
        </FONT>
    
    </MARQUEE> 
    
    </div>
    <div class="col-lg-2 "></div>
    </div>
  
<section class="row sticky-top" >
    <nav class="navbar navbar-expand-lg navbar-light bg-white text-white"   id="menu-nav">
              <span class=""  style="max-width:120px;margin-top:-.8%;"><img class="img-fluid" src="assets/img/logo-slogan.png" alt=""></span>
                       
   <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
       <span class="navbar-toggler-icon"></span>
   </button>

   <div class="collapse navbar-collapse" id="navbarSupportedContent" style="margin-left:5%;">
       <ul class="navbar-nav mr-auto" >
           <li class="nav-item ">
             <a class="nav-link dropdown-item" routerLink = "/inicio" style=" color:#000000;">Inicio
                   <span class="sr-only">Comercializadora de carnes comcasa</span>
               </a></li>
           <li class="nav-item">
             <a class="nav-link dropdown-item " routerLink = "/quienes-somos" style=" color:#000000;">Quiénes Somos</a></li>
         

           <li class="nav-item "><a class="nav-link  dropdown-item " routerLink = "/galeria" style="color:#000000;">Productos</a>
           </li>


           <li class="nav-item "><a class="nav-link  dropdown-item " routerLink = "/contacto" style="color:#000000;">Contacto</a>
           </li>




        <li class="nav-item  dropdown">
          <a class="nav-link  dropdown-toggle dropdown-item" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style=" color:#202750;background-color: #FFFFFF;"> Mayoristas </a>

        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" routerLink = "/promociones" style=" color:#202750; background-color: #FFFFFF;">Promociones</a>
          <a class="dropdown-item" routerLink = "/precios" style=" color:#202750; background-color: #FFFFFF;">Precios</a>
          <a class="dropdown-item" routerLink = "/beneficios" style=" color:#202750; background-color: #FFFFFF;">Beneficios</a>

        </div>

     </li> 

         


              
        
       </ul>
   

   </div>
       <span class="d-none d-lg-block" style="max-width:130px;"><img class="img-fluid" src="assets/img/logo-slogan.png" alt=""></span>
</nav>
 </section>

 
   <!--banner promocion-->
  

</div>

