import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-jamon-de-pavo',
  templateUrl: './jamon-de-pavo.component.html',
  styleUrls: ['../../../../assets/css/descripcion-productos.css']
})
export class JamonDePavoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
