export class Contacto{
    constructor(
    public nombre:string ,
    public correo:string ,
    public telefono:string ,
    public alcaldia:string ,
    public colonia:string ,
    public calle:string,
    public numero:string ,
    public empresa:string,
    public comentario: string,
    ){}
    }