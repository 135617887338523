<section class="row">
  <hr style="background-color: #FA3910;width:100%;">
  <div class="col-md-12">
    <section id="contact" class="white">
      <div class="row" style="text-align: left;">

        <div class="col-md-1"></div>
        <div class="col-md-5">
          <h3 id="h33">Contacto</h3>
          <div id="message">
          </div>



          <form #formContacto=ngForm (ngSubmit)="guardarContacto()" class="row" style="padding:10px;">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12">
                  <div class="box">
                    <span class="contacto-error" *ngIf="nombre.invalid ">*El nombre es requerido</span>
                  </div>

                  <input type="text" class="mcontacto" [(ngModel)]="contacto.nombre" name="nombre" #nombre="ngModel"
                    placeholder="Nombre del propietario" required />
                </div>

                <div class="col-md-12">
                  <input type="text" class="mcontacto" [(ngModel)]="contacto.empresa" name="empresa"
                    placeholder="Nombre del negocio" />
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="box">
                    <span class="contacto-error" *ngIf="correo.invalid">*El correo es requerido</span>
                  </div>

                  <input type="email" class="mcontacto" [(ngModel)]="contacto.correo" name="correo" #correo="ngModel"
                    placeholder="Correo electrónico" [email]="true" required />
                </div>
                <div class="col-md-6">
                  <div class="box">
                    <span class="contacto-error" *ngIf="telefono.invalid ">*El teléfono es requerido</span>

                  </div>
                  <input type="number " class="mcontacto" [(ngModel)]="contacto.telefono" name="telefono"
                    #telefono="ngModel" placeholder="Teléfono" required />

                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <input type="text" class="mcontacto" [(ngModel)]="contacto.alcaldia" name="alcaldia"
                    placeholder="Alcaldía" />
                </div>
                <div class="col-md-6">
                  <input type="text" class="mcontacto" [(ngModel)]="contacto.colonia" name="colonia"
                    placeholder="Colonia" />
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <input type="text" class="mcontacto" [(ngModel)]="contacto.calle" name="calle" placeholder="Calle" />
                </div>
                <div class="col-md-6">
                  <input type="text" class="mcontacto" [(ngModel)]="contacto.numero" name="numero"
                    placeholder="Número" />
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <textarea type="text" class="mcontacto" [(ngModel)]="contacto.comentario" name="comentario"
                    placeholder="Comentario"></textarea>

                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="box">
                    <span class="contacto-error" *ngIf="!privacidad">*Es requerido aceptar póliticas</span>

                  </div>
                  <input type="checkbox" [(ngModel)]="privacidad" name="privacidad" required><span>

                    <!-- Button trigger modal -->


                    <!--<a data-target="#exampleModLong"> Aceptar Póliticas de Privacidad .</a>-->
                    <a data-toggle="modal" data-target="#exampleModalLong">
                      Aceptar Póliticas de Privacidad .
                    </a>

                    <!-- Modal -->
                    <div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog"
                      aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                      <div class="modal-dialog" role="document">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Terminos y condiciones</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body">
                            Datos personales:
                            Cualquier información concerniente a una persona física identificada o identificable.
                            Recabamos tus datos
                            personales de forma directa cuando tú mismo nos los proporcionas por diversos medios, en
                            nuestras
                            instalaciones, en forma telefónica, como cuando participa en nuestras promociones o cuando
                            nos da
                            información con objeto de que le prestemos un servicio o adquiera nuestros productos.
                          </div>
                          <div class="modal-footer">
                            <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
                          </div>
                        </div>
                      </div>
                    </div>





                  </span>


                </div>
              </div>
              <div class="row">
                <div class="col-md-12" style="text-align: center;">
                  <button class="btn btn-danger" name="submit" value="enviar" [disabled]="!formContacto.valid "
                    *ngIf="!estatus">
                    <span *ngIf="!loading">enviar</span>
                    <div id="fountainG" *ngIf="loading">
                      <div id="fountainG_1" class="fountainG"></div>
                      <div id="fountainG_2" class="fountainG"></div>
                      <div id="fountainG_3" class="fountainG"></div>
                      <div id="fountainG_4" class="fountainG"></div>
                      <div id="fountainG_5" class="fountainG"></div>
                      <div id="fountainG_6" class="fountainG"></div>
                      <div id="fountainG_7" class="fountainG"></div>
                      <div id="fountainG_8" class="fountainG"></div>
                    </div>
                  </button>
                </div>
              </div>

              <div class="row">
                <div class="alert-success col-md-12" style="font-size: 10px;" role="alert" *ngIf="estatus">
                  <hr>
                  <h6 class="text-center">Respuesta del servidor</h6>
                  <h6 class="text-center">{{mensaje}}</h6>
                </div>

              </div>

            </div>




          </form>

        </div>

        <div class=" col-md-1 col-sm-1"></div>
        <div class="col-md-5 col-sm-5">
          <h3 id="h33">Ventas</h3>
          <div>


            <div class="social-icons">
              <strong id="h22">Pedidos &nbsp;&nbsp;&nbsp; </strong> Tel:
              55-1696-4590&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <a href="https://api.whatsapp.com/send?phone=5215576145910" target="_blank"
                class="faa-parent animated-hover">
                <i class="fa fa-whatsapp fa-3x faa-falling" style="color:#FA3910;">
                </i>
              </a>

            </div>
            <span class="fa fa-envelope" style="color:#919191;">
              <strong id="h22"> e-mail:ventas@kami-comcasa.com.mx
              </strong>
            </span><br />
          </div>
          <!-- col -->
          <br />
          <div class="row">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3766.604616524935!2d-99.10710278509607!3d19.256055886984953!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85ce0157105aefd7%3A0xcd460a4b393f8fa3!2sComcasa!5e0!3m2!1ses!2smx!4v1513882427294"
              style="width:300;height:250px" frameborder="0" style="border:5px" allowfullscreen>
            </iframe>

          </div>


        </div>
      </div>
      <!-- col -->
      <!-- row -->
    </section>
  </div>
  <hr style="background-color: #FA3910;width:100%;">
</section>